import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import Loadable from "ui-component/Loadable";
import { lazy } from "react";

// application e-commerce pages
const AppECommProducts = Loadable(
  lazy(() => import("views/cattle-page/Products"))
);
const AppECommProductDetails = Loadable(
  lazy(() => import("views/cattle-page/ProductDetails"))
);
const TermsConditions = Loadable(lazy(() => import("views/terms-conditions")));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    { path: "/", element: <AppECommProducts /> },
    { path: "/cattle", element: <AppECommProducts /> },
    { path: "/cattle/:id", element: <AppECommProductDetails /> },
    { path: "/terms-conditions", element: <TermsConditions /> },
    MainRoutes,
    LoginRoutes,
    AuthenticationRoutes,
  ]);
}
