import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

import MinimalLayout from "layout/MinimalLayout";
// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));
// application e-commerce pages
const AppECommProducts = Loadable(
  lazy(() => import("views/cattle-page/Products"))
);
const AppECommProductDetails = Loadable(
  lazy(() => import("views/cattle-page/ProductDetails"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/sample-page",
      element: <SamplePage />,
    },
    // {
    //   path: "/cattle/",
    //   element: <AppECommProducts />,
    // },
    // {
    //   path: "/cattle/:id",
    //   element: <AppECommProductDetails />,
    // },
  ],
};

export default MainRoutes;
