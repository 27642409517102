import Spinner from "components/Spinner";
import { Suspense, LazyExoticComponent, ComponentType } from "react";

// project imports
import { LoaderProps } from "./Loader";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (
  Component:
    | LazyExoticComponent<() => JSX.Element>
    | ComponentType<React.ReactNode>
) => (props: LoaderProps) => (
  <Suspense fallback={<Spinner />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
