import axios from 'axios'
import useAuth from 'hooks/useAuth'
import { Deserializer } from 'jsonapi-serializer'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  LOGIN,
  LOGOUT,
} from 'store/actions'
import axiosServices from 'utils/axios'

export const ReinitializeUserIsLogin = ({
  children,
}: {
  children: React.ReactElement
}) => {
  const dispatch = useDispatch()
  // const navigate = useNavigate();
  // const location = useLocation();
  // console.log('0', location);
  const { isLoggedIn } = useAuth()

  const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
      localStorage.setItem('serviceToken', serviceToken)
      axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`
    } else {
      localStorage.removeItem('serviceToken')
      delete axios.defaults.headers.common.Authorization
    }
  }

  useEffect(() => {
    // const init = async () => {
    //   try {
    //     const serviceToken = window.localStorage.getItem("serviceToken");
    //     if (serviceToken) {
    //       dispatch({
    //         type: RELOAD_PAGE,
    //       });
    //     } else {
    //       dispatch({
    //         type: LOGOUT,
    //       });
    //     }
    //   } catch (err) {
    //     console.error(err);
    //     dispatch({
    //       type: LOGOUT,
    //     });
    //   }
    // };

    // init();

    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken')
        if (serviceToken) {
          setSession(serviceToken)
          const response = await axiosServices.post(
            '/api/v1/routers/usersRoute/getLoggedInUserDetails'
          )

          // deserialize user
          const user = await new Deserializer({
            keyForAttribute: 'camelCase',
          }).deserialize(response.data)

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          })
        } else {
          dispatch({
            type: LOGOUT,
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: LOGOUT,
        })
      }
    }

    init()
  }, [isLoggedIn, dispatch])

  return <>{children}</>
}
export default ReinitializeUserIsLogin
