// project imports
import MinimalLayout from "layout/MinimalLayout";

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [],
};

export default AuthenticationRoutes;
