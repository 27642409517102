/**
 * axios setup to use mock service
 */

import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { Deserializer, Serializer } from "jsonapi-serializer";

const BASEURL = process.env.REACT_APP_BASE_URL;

const axiosServices = axios.create({
  baseURL: BASEURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// TODO
//  need to implement refresh token. currently server don't have any refresh token api
axiosServices.interceptors.request.use();

// interceptor for http
axiosServices.interceptors.request.use((request) => {
  const token = localStorage.getItem("serviceToken");
  if (token) {
    request.headers!.Authorization = `Bearer ${token}`;
  }

  return request;
});

// refresh token
// axiosServices.interceptors.request.use(
//   async (config) => {
//     const token = localStorage.getItem("serviceToken");
//     try {
//       if (token) {
//         const { exp }: any = jwt_decode(token);
//         if (dayjs(exp * 1000 - 1000 * 60 * 30).diff(dayjs()) < 1) {
//           const res = await axios.post(
//             `${BASEURL}/api/v1/routers/usersRoute/refreshToken`,
//             token
//           );
//           if (res.status === 200) {
//             const refreshTokenDeserializer = await new Deserializer({
//               keyForAttribute: "camelCase",
//             }).deserialize(res);
//             localStorage.setItem(
//               "serviceToken",
//               refreshTokenDeserializer.meta.refreshToken
//             );
//           }
//         }
//       }
//     } catch (e) {
//       localStorage.removeItem("serviceToken");
//     }
//     return config;
//   },
//   (err) => {
//     console.log("error in getting ", err);
//   }
// );

export default axiosServices;
