import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import reducer from "./reducer";
// ==============================|| REDUX - MAIN STORE ||============================== //
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
const persister = persistStore(store);
export { store, persister };

export type RootState = ReturnType<typeof reducer>;
