// action - state management
import {
  ALL_PRODUCTS,
  CLEAR_JAT,
  CLEAR_SEARCH,
  CLEAR_TYPE,
  LOGIN,
  LOGOUT,
  REGISTER,
  RELOAD_PAGE,
  SET_CATEGORY,
  SET_COLOR,
  SET_DISTRICT,
  SET_DIVISION,
  SET_JAT,
  SET_MAX,
  SET_MIN,
  SET_TEETH,
  SET_TYPE,
  SET_UNION,
  SET_UPAZILLA,
  TOP_PRODUCTS,
} from "./actions";
import { initialLoginContextProps } from "types";
import { LOCATION_CHANGE } from "react-router-redux";

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState: initialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  division: "%",
  district: "%",
  upazilla: "%",
  union: "%",
  praniCategoryId: "%",
  praniColorId: "%",
  praniJatId: "%",
  min: "%",
  max: "%",
  praniTypeId: "%",
  teethId: "%",
  allProducts: [],
  topProducts: [],
};

export interface AccountReducerActionProps {
  type: string;
  payload?: initialLoginContextProps;
}

const accountReducer = (
  state = initialState,
  action: AccountReducerActionProps
) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload!;
      return {
        ...state,
        user,
      };
    }
    case LOGIN: {
      const { user } = action.payload!;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
      };
    }
    case RELOAD_PAGE: {
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
      };
    }

    case SET_DIVISION: {
      return {
        ...state,
        division: action.payload!,
      };
    }
    case SET_DISTRICT: {
      return {
        ...state,
        district: action.payload!,
      };
    }

    case SET_UPAZILLA: {
      return {
        ...state,
        upazilla: action.payload!,
      };
    }

    case SET_UNION: {
      return {
        ...state,
        union: action.payload!,
      };
    }

    case SET_CATEGORY: {
      return {
        ...state,
        praniCategoryId: action.payload!,
      };
    }

    case SET_COLOR: {
      return {
        ...state,
        praniColorId: action.payload!,
      };
    }

    case SET_JAT: {
      return {
        ...state,
        praniJatId: action.payload!,
      };
    }

    case SET_MIN: {
      return {
        ...state,
        min: action.payload!,
      };
    }

    case SET_MAX: {
      return {
        ...state,
        max: action.payload!,
      };
    }

    case SET_TYPE: {
      return {
        ...state,
        praniTypeId: action.payload!,
      };
    }

    case SET_TEETH: {
      return {
        ...state,
        teethId: action.payload!,
      };
    }

    case CLEAR_TYPE: {
      return {
        ...state,
        praniTypeId: "%",
      };
    }

    case CLEAR_JAT: {
      return {
        ...state,
        praniJatId: "%",
      };
    }

    case LOCATION_CHANGE: {
      return {
        ...state,
        division: "%",
        district: "%",
        upazilla: "%",
        union: "%",
        praniCategoryId: "%",
        praniColorId: "%",
        praniJatId: "%",
        min: "%",
        max: "%",
        praniTypeId: "%",
        teethId: "%",
      };
    }

    case CLEAR_SEARCH: {
      return {
        ...state,
        division: "%",
        district: "%",
        upazilla: "%",
        union: "%",
        praniCategoryId: "%",
        praniColorId: "%",
        praniJatId: "%",
        min: "%",
        max: "%",
        praniTypeId: "%",
        teethId: "%",
      };
    }

    case ALL_PRODUCTS: {
      return {
        ...state,
        allProducts: action.payload!,
      };
    }

    case TOP_PRODUCTS: {
      return {
        ...state,
        topProducts: action.payload!,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
