// material-ui
import { useTheme } from "@mui/material/styles";
import logo from "assets/images/Logo.png";
import logoDark from "assets/images/logo-dark.svg";
import { Link } from "react-router-dom";

const Logo = () => {
  const theme = useTheme();

  return (
    <Link to="/cattle">
      <img
        src={theme.palette.mode === "dark" ? logoDark : logo}
        alt="CoP"
        width="140"
      />
    </Link>
  );
};

export default Logo;
