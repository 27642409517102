// third-party
// reducer - state management
import axios from "axios";
import { Deserializer, Serializer } from "jsonapi-serializer";
import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { LOGIN, LOGOUT } from "store/actions";
import axiosServices from "utils/axios";
import useSnackbar from "./useSnackbar";

// ==============================|| AUTH HOOKS ||============================== //
// const verifyToken: (st: string) => boolean = (serviceToken) => {
//   if (!serviceToken) {
//     return false;
//   }
//   const decoded: any = jwtDecode(serviceToken);
//   /**
//    * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
//    */
//   return decoded.exp > Date.now() / 1000;
// };

const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const useAuth = () => {
  const account = useSelector((state: RootState) => state.account);
  const dispatch = useDispatch();
  const snackbarMsg = useSnackbar();

  const login = async (email: string, password: string) => {
    try {
      const dataSerialize = new Serializer("data", {
        attributes: ["username", "password"],
      }).serialize({ username: email, password });

      const response = await axiosServices.post(
        "api/v1/routers/usersRoute/userLogin",
        dataSerialize
      );

      // deserialize response
      const responseDeserializer = await new Deserializer({
        keyForAttribute: "camelCase",
      }).deserialize(response);

      // deserialize user
      const user = await new Deserializer({
        keyForAttribute: "camelCase",
      }).deserialize(response.data);

      // token
      const token = responseDeserializer.meta.accessToken;

      // refresh token
      const refreshTokenSerializer = new Serializer("data", {
        keyForAttribute: "camelCase",
        attributes: ["accessToken"],
      }).serialize({ accessToken: token });

      const refreshTokenRes = await axiosServices.post(
        `/api/v1/routers/usersRoute/refreshToken`,
        refreshTokenSerializer
      );

      const refreshTokenDeserializer = await new Deserializer({
        keyForAttribute: "camelCase",
      }).deserialize(refreshTokenRes);

      if (refreshTokenDeserializer.meta.refreshToken) {
        setSession(refreshTokenDeserializer.meta.refreshToken);
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user,
          },
        });
      } else {
        snackbarMsg("Invalid Request", "error");
      }
    } catch (error) {
      snackbarMsg("Failed to login!", "error");
      console.log(error);
    }
  };

  const register = async (
    firstName: string,
    username: string,
    password: string,
    phoneNumber: string,
    email: string,
    address: string
  ) => {
    // todo: this flow need to be recode as it not verified
    // const id = chance.bb_pin();
    // console.log(firstName, username, password, phoneNumber, email, address);

    // serialize data
    const userData = new Serializer("users", {
      keyForAttribute: "camelCase",
      attributes: [
        "firstName",
        "username",
        "password",
        "phoneNumber",
        "email",
        "address",
      ],
    }).serialize({
      firstName,
      username,
      password,
      phoneNumber,
      email,
      address,
    });

    const response = await axiosServices.post(
      "/api/v1/routers/usersRoute/createUser",
      userData
    );

    const resultDeserializer = await new Deserializer({
      keyForAttribute: "camelCase",
    }).deserialize(response);

    const resultDeserializerUser = await new Deserializer({
      keyForAttribute: "camelCase",
    }).deserialize(response.data);

    // console.log(resultDeserializerUser);

    let users: any[] = [
      {
        id: resultDeserializerUser?.id,
        phoneNumber: userData?.attributes?.phoneNumber,
        firstName: userData?.attributes?.firstName,
      },
    ];

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = [
        ...JSON.parse(localUsers!),
        {
          id: resultDeserializerUser?.id,
          phoneNumber: userData?.attributes?.phoneNumber,
          firstName: userData?.attributes?.firstName,
        },
      ];
    }

    window.localStorage.setItem(
      "users",
      JSON.stringify(resultDeserializerUser)
    );
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  // if (account.isInitialized !== undefined && !account.isInitialized) {
  //     return <Loader />;
  // }

  return { ...account, login, logout, register, resetPassword, updateProfile };
};

export default useAuth;
